<template>
  <div class="page">
    <aside class="page__navigator">
      <side-nav :nav-items="nav" />
    </aside>
    <main class="page__main">
      <transition
        name="fade"
        mode="out-in"
      >
        <spinner v-if="isLoading" />
        <router-view v-else />
      </transition>
    </main>
  </div>
</template>

<script>
import SideNav from '@/components/layout/SideNav'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleSetting',
  components: {
    SideNav
  },
  computed: {
    ...mapGetters('scheduleSetting', ['isYKSchedule']),
    nav () {
      return [
        {
          title: this.$t('schedule.header.setting'),
          icon: 'filter-setting',
          subNav: [
            { title: this.$t('schedule.setting.title'), routeName: 'ScheduleBaseSetting' },
            { title: this.$t('schedule.setting.commonDataSetting'), routeName: 'ScheduleCommonDataSetting' },
            { title: this.$t('schedule.setting.extraConstraintSetting'), routeName: 'ScheduleConstraintSetting' },
            ...this.isYKSchedule ? [
              { title: this.$t('schedule.setting.resultColumnSetting'), routeName: 'ScheduleSimulationDataColumnSetting' }
            ] : [
              { title: this.$t('schedule.splitMergeSetting.name'), routeName: 'ScheduleSplitMergeSetting' },
              { title: this.$t('schedule.setting.versionManagement'), routeName: 'ScheduleVersionManagement' }
            ]
          ]
        }
      ]
    }
  },
  data () {
    return {
      isLoading: true,
      defaultSetting: {}
    }
  },
  mounted () {
    this.fetchDefaultSetting()
  },
  methods: {
    fetchDefaultSetting () {
      this.$store.dispatch('scheduleSetting/getDefaultSetting')
        .then(([baseSetting, splitMergeSetting, operationSplitMergeRules, productSplitMergeBlacklist]) => {
          const formattedWorktimes = {}
          if (this.isYKSchedule) {
            this.$store.commit('scheduleSetting/updateSetting', baseSetting)
            return
          } else {
            // NOTICE 邏輯重複，在 sp2 已整進 actions 中
            // 不是鈺齊環境，要把 worktimes 格式統一成新版（含 hours, ranges）
            for (const [weekday, shifts] of Object.entries(baseSetting.worktimes)) {
              formattedWorktimes[weekday] = {
                hours: 0,
                ranges: shifts
              }
            }
          }
          this.defaultSetting = {
            ...baseSetting,
            worktimes: this.isYKSchedule ? baseSetting.worktimes : formattedWorktimes,
            splitMergeSetting: {
              splitMergeType: splitMergeSetting.splitMergeType,
              splitThreshold: splitMergeSetting.productSplitThreshold,
              mergeThreshold: splitMergeSetting.productMergeThreshold,
              operationSplitMergeRules,
              productSplitMergeBlacklist
            }
          }
          this.$store.commit('scheduleSetting/updateSetting', this.defaultSetting)
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  height: 100%;

  &__navigator {
    flex: 0 0 $side-nav-width;
  }

  &__main {
    flex: 1;
    overflow: auto;
    overflow: overlay;
  }

  .save-btn {
    border-radius: 6px;
    height: 40px;
    margin-top: 16px;
  }

  ::v-deep .file {
    background: rgba(0, 0, 0, 0.55);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &__item {
      background: rgba(35, 61, 64, 0.6);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      padding: 24px;
      width: 100%;
    }

    &__item-info {
      flex: 1;
    }

    &__item-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 13px;
    }

    &__item-description {
      display: flex;
      font-size: 14px;
    }

    &__item-name {
      flex: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 245px;

      &.is-empty {
        color: #aaa;
      }
    }

    &__item-date {
      flex: 2;

      &.is-empty {
        color: #aaa;
      }
    }

    &__item-button-block {
      display: flex;
      justify-content: flex-end;
    }

    &__item-button {
      align-items: center;
      display: flex;
      margin-left: 12px;
    }
  }

  .empty-block {
    background-color: rgba(100, 100, 100, 0.2);
    border-radius: 4px;
    color: #ccc;
    flex-basis: 100%;
    font-size: 14px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
}
</style>
